<template>
    <div id="subscription-page">
       
        <vs-alert v-if="!this.can_see_page" color="warning" :title="$t('AccessDeniedTitle')" active="true">
          <p>{{ $t(this.AccessDeniedMessage) }}<br>[{{ this.AccessDeniedInfos }}]</p>
        </vs-alert>

        <template v-else>

          <vs-tabs position="top" color="primary">
            <!-- settings tab -->
            <vs-tab :label="$t('Customize')" icon-pack="feather" icon="icon-settings">
              
              <!-- portal settings -->
              <vx-card class="mt-4" :title="$t('portalSettings')">
                <!-- portal_nicename -->
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>{{$t('portalNicename')}}</span>
                  </div>
                  <div class="vx-col sm:w-3/4 w-full">
                    <vs-input class="w-full" v-model="local_portal_nicename" @blur="prepareForm()" />
                  </div>
                </div>
                <!-- portal_slug -->
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>{{$t('portalSlug')}}</span>
                  </div>
                  <div class="vx-col sm:w-3/4 w-full">
                    <vx-input-group class="mb-base">
                      <template slot="prepend">
                        <div class="prepend-text" :class="[local_portal_slug !== $store.state.portal_slug ? 'bg-danger' : 'bg-primary']">
                          <span>{{this.$appConfig.baseUrl}}/</span>
                        </div>
                      </template>
                      <vs-input class="w-full" v-model="local_portal_slug" 
                      @blur="prepareForm()" @input="resetChecks()"/>
                    </vx-input-group>
                    <!-- si changement -->
                    <template v-if="local_portal_slug !== $store.state.portal_slug">
                      <vs-alert v-if="!newPortalSlugAvailable && newPortalSlugChecked" :title="$t('error')" color="danger" active="true">{{ $t('portalSlugNotAvailable') }}</vs-alert>
                      <vs-alert v-else :title="$t('danger')" color="danger" active="true"><p>{{ $t('portalSlugAlertMessage') }}</p></vs-alert>
                    </template>
                  </div>
                </div>
                <!-- button -->
                <div class="flex mb-4">
                  <vs-button class="w-full h-12" :disabled="!btnEnabled" color="primary" icon-pack="feather" icon="icon-save" @click="updatePortalInfosConfirm()">{{$t('Save')}}</vs-button>
                </div>
              </vx-card>

              <vx-card class="mt-4" :title="$t('projectsSettings')">
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-3/4 w-full">
                    <span>{{$t('projectsLocation')}}</span>
                  </div>
                  <div class="vx-col sm:w-1/4 w-full">
                    <vs-switch v-model="projectsLocation" @click="updateProjectsParameter('projects_location', !projectsLocation)"/>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-3/4 w-full">
                    <span>{{$t('projectsLegend')}}</span>
                  </div>
                  <div class="vx-col sm:w-1/4 w-full">
                    <vs-switch v-model="projectsLegend" @click="updateProjectsParameter('projects_legend', !projectsLegend)"/>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-3/4 w-full">
                    <span>{{$t('projectsCoordinatesSystem')}}</span>
                  </div>
                  <div class="vx-col sm:w-1/4 w-full">
                    <vs-switch v-model="projectsCoordinatesSystem" @click="updateProjectsParameter('projects_coordinates_system', !projectsCoordinatesSystem)"/>
                  </div>
                </div>
                <!-- <div class="vx-row mb-6">
                  <div class="vx-col sm:w-3/4 w-full">
                    <span>{{$t('rdp')}}</span>
                  </div>
                  <div class="vx-col sm:w-1/4 w-full">
                    <vs-switch disabled="true" v-model="rdp" @click="updateProjectsParameter('rdp', !rdp)"/>
                  </div>
                </div> -->
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-3/4 w-full">
                    <span>{{$t('viewerVersion')}}</span>
                  </div>
                  <div class="vx-col sm:w-1/4 w-full">
                    <vs-input disabled class="w-full" v-model="$store.state.viewerVersion"/>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-3/4 w-full">
                    <feather-icon icon="PackageIcon" svgClasses="stroke-current text-primary w-6 h-6" class="ml-2" />
                    <span>{{$t('betaTester')}}</span>
                  </div>
                  <div class="vx-col sm:w-1/4 w-full">
                    <vs-switch disabled="true" v-model="betaTester" @click="updateProjectsParameter('beta_tester', !betaTester)"/>
                  </div>
                </div>
              </vx-card>

              <vx-card class="mt-4" :title="$t('sharedPortalProjectInformations')">
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>{{$t('portalSlug')}}</span>
                  </div>
                  <div class="vx-col sm:w-3/4 w-full">
                    <vs-input disabled class="w-full" v-model="$store.state.portal_slug"/>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>{{$t('userId')}}</span>
                  </div>
                  <div class="vx-col sm:w-3/4 w-full">
                    <vs-input disabled class="w-full" v-model="$store.state.AppActiveUser.user_id"/>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>{{$t('controlKey')}}</span>
                  </div>
                  <div class="vx-col sm:w-3/4 w-full">
                    <vs-input disabled class="w-full" v-model="projectPortalShareKey"/>
                  </div>
                </div>
              </vx-card>

            </vs-tab>

            <!-- quotas -->
            <vs-tab :label="$t('Subscription')" icon-pack="feather" icon="icon-cloud">
              <vx-card class="mt-4" :title="$t('currentUsage')">
                <subscription-quotas class="mt-8" :actualisation="incrementedSubscriptionCheck" display users disk layers projects time/>
              </vx-card>
              <vx-card class="mt-4" :title="$t('changePlan')">
                {{$t('pleaseContactUs')}} : {{this.$appConfig.contactEmail}}
              </vx-card>
            </vs-tab>

          </vs-tabs>
            
        </template>

    </div>
</template>
<script>
import axios from '@/axios.js'
import SubscriptionQuotas from './subscription/quotas.vue'
import moment from 'moment'
import logActivity from '@/logActivity.js'
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'

export default {
  components: {
    SubscriptionQuotas
  },
  data () {
    return {
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getPortalSubscriptionUsage: 'getPortalSubscriptionUsage',
      updatePortalSlug: 'updatePortalSlug',
      updatePortalNicename: 'updatePortalNicename',
      updatePortalProjectsParameter: 'updatePortalProjectsParameter',

      // ROYBON / Subscription usage
      incrementedSubscriptionCheck:0,

      // ROYBON / Interprétation des droits
      can_see_page: null,
      AccessDeniedMessage: '',
      AccessDeniedInfos: '',

      // ROYBON / Changement des paramètres du portal
      local_portal_nicename: '',
      local_portal_slug: '',
      newPortalSlugChecked: false,
      newPortalSlugAvailable: false,

      // ROYBON / Paramètres des projets
      mounted: false,
      projectsParametersLoaded: false,
      projectsLocation: '',
      projectsLegend:  '',
      projectsCoordinatesSystem:  '',
      betaTester:  '',
      rdp:  '',
      viewerVersion:  '',
      viewerBaseUrl:  ''
    }
  },
  methods: {    

    // ROYBON / Obtient l'état des paramètres de projets
    getProjectsParameters () {
      const params = `portal_slug=${this.$store.state.portal_slug}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/public/getPortalBySlug?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (null !== response) {
            if (response.data != null && response.data != 'null') {
              if (!isNaN(response.data.portal_id)) {
                this.projectsLocation               = response.data.projects_location == '1'
                this.$store.commit('UPDATE_PROJECTS_LOCATION', this.projectsLocation)
                this.$store.dispatch('updateProjectsLocation', this.projectsLocation)
                
                this.projectsLegend                 = response.data.projects_legend == '1'
                this.$store.commit('UPDATE_PROJECTS_LEGEND', this.projectsLegend)
                this.$store.dispatch('updateProjectsLegend', this.projectsLegend)
                
                this.projectsCoordinatesSystem      = response.data.projects_coordinates_system == '1'
                this.$store.commit('UPDATE_PROJECTS_COORDINATES_SYSTEM', this.projectsCoordinatesSystem)
                this.$store.dispatch('updateProjectsCoordinatesSystem', this.projectsCoordinatesSystem)
                
                this.betaTester      = response.data.beta_tester == '1'
                this.$store.commit('UPDATE_BETA_TESTER', this.betaTester)
                this.$store.dispatch('updateBetaTester', this.betaTester)

                this.rdp      = response.data.rdp == '1'
                this.$store.commit('UPDATE_RDP', this.rdp)
                this.$store.dispatch('updateRdp', this.rdp)

                this.viewerVersion = response.data.viewer_version
                this.$store.commit('UPDATE_VIEWER_VERSION', this.viewerVersion)
                this.$store.dispatch('updateViewerVersion', this.viewerVersion)
                
                this.projectsParametersLoaded = true
              }
            }
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Update les paramètres des projets
    updateProjectsParameter (param, value) {
      value ? value = '1' : value = '0'
      //const params = `portal_id=${this.$store.state.portal_id}&parameter=${param}&value=${value}`
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('parameter', `${param}`)
      params.set('value', `${value}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.updatePortalProjectsParameter}`
      axios.post(rimnat_api_call_url, params)
        .then(() => { 
          this.$vs.notify({
            color:'success',
            title:this.$t('ParameterUpdated')
          })
          logActivity.add('portal_projects_parameters_update', param)
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Fonction de nettoyage du titre
    sanitizeTitle (value) {
      const regex = /(<([^>]+)>)/ig
      value = value.replace(regex, '')
      value = value.replace(/ +(?= )/g, '')
      value = value.trim()
      return value
    },
    // ROYBON / Fonction de formattage de string pour URL
    urlify (value) {
      if (!value) return ''
      value = value.toString()
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      value = value.replace(/[^0-9a-z]/gi, '-')
      value = value.replace(/ /g, '-')
      value = value.substring(0, 20)
      value = value.replace(/-/gi, ' ')
      value = value.trim()
      value = value.replace(/ /gi, '-')
      value = value.replace(/ /gi, '-')
      value = value.toLowerCase()
      value = value.split('-').join('')
      value = value.split('   ').join(' ')
      value = value.split('  ').join(' ')
      return value
    },

    // ROYBON / Reset checks
    resetChecks () {
      this.newPortalSlugAvailable = true
      this.newPortalSlugChecked = false
    },
    // ROYBON / Clean les valeurs
    prepareForm () {
      this.local_portal_nicename = this.sanitizeTitle(this.local_portal_nicename)
      this.local_portal_slug = this.urlify(this.local_portal_slug)
      this.checkPortalSlugAvailability()
    },

    // ROYBON / Vérifie la disponibilité du portal_slug
    checkPortalSlugAvailability () {
      if (this.local_portal_slug !== '') {
        if (this.local_portal_slug.length < 3 || this.local_portal_slug.length > 20) {
          this.newPortalSlugAvailable = false
          this.newPortalSlugChecked = true
        } else if (!isNaN(this.local_portal_slug)) {
          this.newPortalSlugAvailable = false
          this.newPortalSlugChecked = true        
        } else {
          const params = `portal_slug=${this.local_portal_slug}`
          const rimnat_api_call_url = `${this.api_server_baseurl}/public/getPortalBySlug?${params}`
          this.newPortalSlugAvailable = true 
          axios.get(rimnat_api_call_url, {
          })
            .then((response) => { 
              if (null !== response) {
                if (null !== response.data) {
                  if (!isNaN(response.data.portal_id)) {
                    if (parseInt(response.data.portal_id) !== parseInt(this.$store.state.portal_id)) {
                      this.newPortalSlugAvailable = false
                    }
                  }
                }
              }
              this.newPortalSlugChecked = true
            })
            .catch((error)   => { console.log(error) })
        }
      }
    },

    // ROYBON / Confirme l'update des infos du portal
    updatePortalInfosConfirm () {
      // si changement de slug ET de nicename
      if ((this.local_portal_slug !== this.$store.state.portal_slug) && (this.local_portal_nicename !== this.$store.state.portal_nicename)) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: this.$t('danger'),
          text: this.$t('portalSlugAlertMessage'),
          acceptText: this.$t('Confirm'),
          cancelText: this.$t('Cancel'),
          accept: this.updatePortalSlugAndNicenameAction
        })
      }
      // si changement de slug uniquement
      if ((this.local_portal_slug !== this.$store.state.portal_slug) && (this.local_portal_nicename === this.$store.state.portal_nicename)) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: this.$t('danger'),
          text: this.$t('portalSlugAlertMessage'),
          acceptText: this.$t('Confirm'),
          cancelText: this.$t('Cancel'),
          accept: this.updatePortalSlugAction
        })
      }
      // si changement de nicename uniquement
      if ((this.local_portal_slug === this.$store.state.portal_slug) && (this.local_portal_nicename !== this.$store.state.portal_nicename)) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'primary',
          title: this.$t('Confirm'),
          text: this.$t('portalNicenameUpdateConfirm'),
          acceptText: this.$t('Confirm'),
          cancelText: this.$t('Cancel'),
          accept: this.updatePortalNicenameAction
        })
      }
    }, 

    // ROYBON / Changement de slug ET de nicename
    updatePortalSlugAndNicenameAction () {
      this.updatePortalSlugAction(false)
      this.updatePortalNicenameAction()
    },

    // ROYBON / Applique le changement de portal_slug
    updatePortalSlugAction (redirect = true) {
      //const params = `portal_id=${this.$store.state.portal_id}&portal_slug=${encodeURIComponent(this.local_portal_slug)}`
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('portal_slug', `${encodeURIComponent(this.local_portal_slug)}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.updatePortalSlug}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            logActivity.add('portal_slug_update', this.local_portal_slug)
            this.$vs.notify({
              time:30000,
              color:'warning',
              title:this.$t('ParameterUpdated'),
              text:this.$t('portalSlugChanged')
            })
            // ROYBON / Modifie la valeur globale portal_slug dans le store et dans la vue
            this.$store.commit('UPDATE_PORTAL_SLUG', this.local_portal_slug)
            this.$store.dispatch('updatePortalSlug', this.local_portal_slug)
            if (redirect) this.$router.push({ path: `/${this.local_portal_slug}/admin`})
          } else {
            // error
            this.$vs.notify({
              color:'danger',
              text:this.$t('ParameterNotUpdated')
            })
          }          
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Applique le changement de portal_nicename
    updatePortalNicenameAction () {
      //const params = `portal_id=${this.$store.state.portal_id}&portal_nicename=${encodeURIComponent(this.local_portal_nicename)}`
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('portal_nicename', `${encodeURIComponent(this.local_portal_nicename)}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.updatePortalNicename}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            logActivity.add('portal_nicename_update', this.local_portal_nicename)
            this.$vs.notify({
              color:'success',
              title:this.$t('ParameterUpdated'),
              text:this.$t('portalNicenameChanged')
            })
            // ROYBON / Modifie la valeur globale portal_nicename dans le store et dans la vue
            this.$store.commit('UPDATE_PORTAL_NICENAME', this.local_portal_nicename)
            this.$store.dispatch('updatePortalNicename', this.local_portal_nicename)
            this.$router.push({ path: `/${this.local_portal_slug}/admin`})
          } else {
            // error
            this.$vs.notify({
              color:'danger',
              text:this.$t('ParameterNotUpdated')
            })
          }          
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Force l'actualisation des quotas de l'abonnement
    refreshSubscriptionQuotas () {
      this.incrementedSubscriptionCheck = this.incrementedSubscriptionCheck + 1
    },

    // ROYBON / Check si l'affichage de la page est autorisé
    canSeePage () {
      const gc =                this.$store.state.current_user_global_caps
      const poc =               this.$store.state.current_user_portal_caps
      const poc_start_date =    poc.can_portal_access_start_date
      const poc_end_date =      poc.can_portal_access_end_date
      if (gc.can_global_access === '1' && poc.can_portal_access === '1') {
        if (poc_start_date !== null && moment().isSameOrBefore(poc_start_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessNotYetOpened'
          this.AccessDeniedInfos = poc_start_date
        } else if (poc_end_date !== null && moment().subtract(1, 'd').isSameOrAfter(poc_end_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessExpired'
          this.AccessDeniedInfos = poc_end_date

        } else if (poc.can_portal_manage !== '1') {
          this.can_see_page = false
          this.AccessDeniedMessage = 'cannotManagePortal'
        } else {
          this.can_see_page = true
        }
      } else {
        this.can_see_page = false
        this.AccessDeniedMessage = 'PortalAccessDeniedFull' 
        this.AccessDeniedInfos = 'global or portal access invalid'
      }
    }
  },

  computed: {
    projectPortalShareKey: function () {
      return sha1(`${this.$store.state.portal_slug}${this.$store.state.AppActiveUser.user_id}`).toString(CryptoJS.enc.sha1)
    },

    btnEnabled: function () {
      let returned = false
      if (this.local_portal_nicename !== this.$store.state.portal_nicename) {
        returned = true
      }
      if (this.local_portal_slug !== this.$store.state.portal_slug) {
        returned = this.newPortalSlugAvailable && this.newPortalSlugChecked
      }
      return returned
    }
  },

  beforeMount () {
    this.canSeePage()
    this.getProjectsParameters()
  },

  created () {
  },
  mounted () {
    // ROYBON / Initialise localement les données du portal
    this.local_portal_nicename = this.$store.state.portal_nicename
    this.local_portal_slug = this.$store.state.portal_slug
    this.mounted = true
  },

  watch: {
    local_portal_slug: function () {
      this.resetChecks()
    },
    projectsLocation: function () {
      if (this.projectsParametersLoaded && this.mounted) {
        this.getProjectsParameters()
      }
    },
    projectsLegend: function () {
      if (this.projectsParametersLoaded && this.mounted) {
        this.getProjectsParameters()
      }
    },
    projectsCoordinatesSystem: function () {
      if (this.projectsParametersLoaded && this.mounted) {
        this.getProjectsParameters()
      }
    }
  }
}

</script>
<style lang="scss">
</style>
